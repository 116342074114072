.home {
    max-width: 100%;
    text-align: center;
}

.home-image-container{
    width: 60vw;
    text-align: center;
    margin:auto;
    padding-top: 40px;
    padding-bottom: 40px;
}

.home-image-container img{
    width: 30vw;
}

.home button{
    font-family: 'Poppins', sans-serif;
    text-align: center;
    width: 40vw;
    height: auto;
    font-weight: 800;
    border-radius: 5px;
    font-size: 18pt;
    margin: auto;
}

@media (max-width: 1000px) {
    
}
