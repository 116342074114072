.app-main {
    background: #7F27DE;
    min-height: 100vh;
    color: white;
    font-family: 'Poppins', sans-serif;
}



@media (pointer:none), (pointer:coarse) {
    .app-main{
       
      }
}